/**
 * @file Input component
 * @author Alwyn Tan
 */

import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import styled from 'styled-components'
import Spinner from './Spinner'

interface ContainerProps {
  error?: boolean
  size?: 'regular' | 'large'
}

const Wrapper = styled.div`
  width: 100%;
`

const Container = styled.div<ContainerProps>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: ${({ size }) => (size === 'regular' ? 16 : 20)}px;
  padding: ${({ size }) => (size === 'regular' ? 10.25 : 14)}px 11px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff1a;
  color: white;
  transition: 0.3s ease border-radius, 0.3s ease border-color;
  border: 4px solid transparent;
  border-color: ${({ error, theme }) => (error ? theme.Error : 'transparent')};
  border-radius: ${({ error }) => (error ? '8px 8px 0 0' : '8px')};
  position: relative;

  > input {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    width: 100%;
    color: inherit;
    outline: none;
    border: 0;
    background-color: transparent;
    display: flex;
  }
`

const Error = styled(motion.div)`
  background-color: ${({ theme }) => theme.Error};
  color: white;
  border-radius: 0 0 8px 8px;
  text-align: left;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`

const ErrorMessage = styled(motion.span)`
  padding: 5px 15px 8px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Inter';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
`

type Props = {
  size?: 'regular' | 'large'
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  onChange?: (value?: string | File) => void
  loading?: boolean
}

const Input = <TFieldValues extends FieldValues = FieldValues>({
  size = 'large',
  inputProps = {},
  onChange = () => {},
  loading,
  ...controllerProps
}: Props & UseControllerProps<TFieldValues>) => {
  const { field, fieldState } = useController({
    ...controllerProps,
    defaultValue: controllerProps.defaultValue || ('' as any), // todo: fix ts issues here
  })

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val =
      inputProps.type === 'file' ? e.target.files?.[0] : e.target.value
    field.onChange(val)
    onChange(val)
  }

  const transform = {
    input: (v: string) => {
      if (inputProps.type === 'file') return undefined
      return v
    },
  }

  return (
    <Wrapper>
      <Container size={size} error={!!fieldState.error}>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          onChange={handleOnChange}
          value={transform.input(field.value)}
          disabled={loading}
          onClick={() => {
            // todo: fix hack that forces a scroll....
            window.scrollTo(0, 260)
          }}
        />
        {loading && <Spinner style={{ position: 'absolute', right: 15 }} />}
      </Container>
      <AnimatePresence>
        {fieldState.error && (
          <Error
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 30, opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ ease: 'easeOut', duration: 0.2 }}
          >
            <ErrorMessage
              initial={{ y: -60 }}
              animate={{ y: 0 }}
              exit={{ y: -60 }}
            >
              {fieldState.error?.message || 'Required'}
            </ErrorMessage>
          </Error>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

export default Input
