/**
 * @file Phone Login Component for AuthModal
 * @author Alwyn Tan
 */

import React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import isMobilePhone from 'validator/lib/isMobilePhone'
import PhoneInput from '#/components/atoms/PhoneInput'
import Text from '#/components/atoms/Text'
import { Purple } from '#/constants/colors'
import useRequestLogin from '#/hooks/query/user/useRequestLogin'
import AuthModalContent from './AuthModalContent'

const TermsText = styled.a`
  color: ${Purple};
  cursor: pointer;
`

type Props = {
  onComplete: (phoneNumber: string) => void
}

type FormValues = {
  phoneNumber: string
}

const PhoneAuth: React.FC<Props> = ({ onComplete }) => {
  const { control, handleSubmit, setError } = useForm<FormValues>({
    mode: 'onChange',
  })
  const requestLoginMutation = useRequestLogin()

  const onSubmit: SubmitHandler<FormValues> = async ({ phoneNumber }) => {
    requestLoginMutation.mutate(phoneNumber, {
      onSuccess: () => onComplete(phoneNumber),
      onError: (e: any) =>
        setError('phoneNumber', { message: e?.message || 'Unknown Error' }),
    })
  }

  const handlePhoneChanged = (phoneNumber: string) => {
    if (isMobilePhone(phoneNumber)) handleSubmit(onSubmit)()
  }

  return (
    <AuthModalContent
      title="Get Started"
      input={
        <PhoneInput
          autoFocus={
            !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          }
          name="phoneNumber"
          control={control}
          onChange={handlePhoneChanged}
          loading={requestLoginMutation.isLoading}
        />
      }
      footer={
        <Text type="p2" style={{ color: '#ffffff80' }}>
          By entering your phone number, you are participating in Disco’s{' '}
          <TermsText
            href="https://godisco.party/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms & conditions
          </TermsText>{' '}
          and allow us to send you SMS messages. You can opt-out at any time.
        </Text>
      }
    />
  )
}

export default PhoneAuth
