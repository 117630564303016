/**
 * @file Analytics event name constants
 * @author Alwyn Tan
 */

// todo: engineer a good cross platform way of syncing all these info

export const OBJECT = {
  DROP: 'Drop',
  PARTNER_MODAL: 'Partner Modal',
} as const

export const ACTION = {
  BUY_CLICKED: 'Buy Clicked',
  PREVIEW_CLICKED: 'Preview Clicked',
  LIKE_CLICKED: 'Like Clicked',
  SHARE_CLICKED: 'Share Clicked',
  LETS_DISCO_CLICKED: 'Lets Disco Clicked',
  CLOSED: 'Closed',
} as const

export const ALLOWED_ACTIONS = {
  [OBJECT.DROP]: {
    [ACTION.BUY_CLICKED]: true,
    [ACTION.PREVIEW_CLICKED]: true,
    [ACTION.LIKE_CLICKED]: true,
    [ACTION.SHARE_CLICKED]: true,
    [ACTION.LETS_DISCO_CLICKED]: true,
  },
  [OBJECT.PARTNER_MODAL]: {
    [ACTION.LETS_DISCO_CLICKED]: true,
    [ACTION.CLOSED]: true,
  },
} as const

export type TObjectName = (typeof OBJECT)[keyof typeof OBJECT]
export type TAction = (typeof ACTION)[keyof typeof ACTION]
