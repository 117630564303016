/**
 * @file Colors
 * @author Alwyn Tan
 */

export const Gray = '#A2A3A3'
export const DarkGray = '#31302F'
export const DarkerGray = '#171818'
export const OffBlack = ' #0C0C0C'
export const Red = '#FC4949'
export const Purple = '#B900E2'
export const Green = '#76FB4D'
export const Yellow = '#FFE81E'
