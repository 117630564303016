/**
 * @file OTP Component for AuthModal
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import Input from '#/components/atoms/Input'
import Text from '#/components/atoms/Text'
import AuthModalContent from '#/components/organisms/Auth/AuthModalContent'
import { Purple } from '#/constants/colors'
import useLogin from '#/hooks/query/user/useLogin'
import useRequestLogin from '#/hooks/query/user/useRequestLogin'

const PhoneNumberText = styled.span`
  cursor: pointer;
  font-weight: bold;
  color: ${Purple};
`

const ResendButton = styled.div<ResendButtonProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  cursor: pointer;
  padding-left: 15px;
`

type ResendButtonProps = {
  disabled: boolean
}

type Props = {
  phoneNumber: string
  onBack: () => void
  onComplete: (otp: string) => void
}

type FormValues = { otp: string }

const OTPAuth: React.FC<Props> = ({ phoneNumber, onBack, onComplete }) => {
  const { control, handleSubmit, setError } = useForm<FormValues>({
    mode: 'onChange',
  })
  const requestLoginMutation = useRequestLogin()
  const loginMutation = useLogin()
  const [timeLeft, setTimeLeft] = useState(30)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(v => {
        if (v > 0) return v - 1
        return v
      })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (requestLoginMutation.isSuccess) {
      setTimeLeft(30)
      const interval = setInterval(() => {
        setTimeLeft(v => {
          if (v > 0) return v - 1
          return v
        })
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }

    return () => {}
  }, [requestLoginMutation.isSuccess])

  const onSubmit: SubmitHandler<FormValues> = async ({ otp }) => {
    loginMutation.mutate(
      { phoneNumber, code: otp },
      {
        onSuccess: () => onComplete(otp),
        onError: (e: any) =>
          setError('otp', { message: e?.message || 'Unknown Error' }),
      }
    )
  }

  const handleOnChange = (v?: string | File) => {
    if (v?.length === 4) handleSubmit(onSubmit)()
  }

  const handleResendClick = () => {
    requestLoginMutation.mutate(phoneNumber.replace(/[() ]/g, ''))
  }

  return (
    <AuthModalContent
      title="Verify It’s You"
      subtitle={
        <>
          Code sent to{' '}
          <PhoneNumberText onClick={onBack}>{phoneNumber}</PhoneNumberText>
        </>
      }
      input={
        <Input
          name="otp"
          control={control}
          onChange={handleOnChange}
          size="regular"
          inputProps={{
            autoFocus: true,
            placeholder: 'Enter Code Here',
            maxLength: 4,
            type: 'tel',
            autoComplete: 'one-time-code',
          }}
          loading={loginMutation.isLoading}
        />
      }
      footer={
        <ResendButton disabled={timeLeft > 0} onClick={handleResendClick}>
          <Text type="button2">
            Resend Code{timeLeft > 0 ? ` (${timeLeft})` : ''}
          </Text>
        </ResendButton>
      }
    />
  )
}

export default OTPAuth
