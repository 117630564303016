import axios from 'axios'
import { GatsbyBrowser } from 'gatsby'
import React from 'react'
import PageWrapper from '#/PageWrapper'
import RootWrapper from '#/RootWrapper'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => <RootWrapper>{element}</RootWrapper>

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => <PageWrapper>{element}</PageWrapper>

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  /* setup all the axios defaults */
  axios.defaults.baseURL = '/api'

  // interceptor to auto silent login when initializing
  axios.interceptors.request.use(async req => {
    const hasRefreshToken = document.cookie.indexOf('rtExists=') !== -1
    const isLoginRequest =
      req?.url?.endsWith('/user/silent-login') ||
      req?.url?.endsWith('/user/login')

    if (
      !axios.defaults.headers.common.Authorization &&
      hasRefreshToken &&
      !isLoginRequest
    ) {
      const { accessToken } = (
        await axios.post(`/user/silent-login`, {}, { withCredentials: true })
      ).data

      if (accessToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        req.headers.Authorization = `Bearer ${accessToken}`
      }
    }

    return req
  })

  // interceptor to auto retry login accessToken fails
  axios.interceptors.response.use(null, async error => {
    const originalRequest = error.config

    if (
      originalRequest?.url !== '/user/silent-login' &&
      originalRequest?.url !== '/user/login' && // extra precaution to not retry logins
      !originalRequest?.retried &&
      error.response?.status === 401
    ) {
      delete axios.defaults.headers.common.Authorization
      originalRequest.retried = true

      const { accessToken } = (
        await axios.post(`/user/silent-login`, {}, { withCredentials: true })
      ).data

      if (accessToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        originalRequest.headers.Authorization = `Bearer ${accessToken}`
      }

      return axios(originalRequest)
    }

    // convert the error into something we'd like
    return Promise.reject(
      new Error(error.response.data?.error?.message || error.message)
    )
  })
}
