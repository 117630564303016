/**
 * @file Context for the bottom sheets throughout the app (might be worth factoring this out into its own library)
 * @author Alwyn Tan
 */

import { AnimatePresence } from 'framer-motion'
import React, { createContext, useContext, useMemo, useState } from 'react'
import Portal from '#/components/atoms/Portal'
import BottomSheet from '#/components/templates/BottomSheet'
import BottomSheetBackdrop from '#/components/templates/BottomSheetBackdrop'

type BottomSheetProviderProps = {
  children: React.ReactNode
}

type Sheet = {
  meta: {
    title: string
    rightHeaderButton?: React.ReactNode
    style?: React.CSSProperties
  }
  node: React.ReactNode
}

type TBottomSheetContext = {
  sheets: Sheet[]
  setSheets: React.Dispatch<React.SetStateAction<Sheet[]>>
}

const BottomSheetContext = createContext<TBottomSheetContext>({
  sheets: [],
  setSheets: () => {},
})

const useBottomSheet = () => {
  const { setSheets } = useContext(BottomSheetContext)

  // generic version
  const presentBottomSheet = (
    node: React.ReactNode,
    title: string,
    {
      rightHeaderButton,
      style,
    }: { rightHeaderButton?: React.ReactNode; style?: React.CSSProperties } = {}
  ) => {
    setSheets(currSheets => [
      ...currSheets,
      {
        meta: { title, rightHeaderButton, style },
        node,
      },
    ])
  }

  /**
   * Pops and removes the latest sheet out of the sheet stack
   */
  const popBottomSheet = () => {
    setSheets(currSheets =>
      currSheets.filter((c, i) => i !== currSheets.length - 1)
    )
  }

  /**
   * Resets the bottom sheet and removes all the sheets
   */
  const resetBottomSheet = () => {
    setSheets([])
  }

  return { presentBottomSheet, popBottomSheet, resetBottomSheet }
}

const BottomSheetProvider = ({ children }: BottomSheetProviderProps) => {
  const [sheets, setSheets] = useState<Sheet[]>([])

  const value = useMemo(() => ({ sheets, setSheets }), [sheets])

  const handleCloseSheet = () => {
    setSheets(currSheets =>
      currSheets.filter((c, i) => i !== currSheets.length - 1)
    )
  }

  // Bottom sheets will remain mounted when new sheets get added, for smoothest experience and state preservation (eg. scroll)
  // Potential optimization in the future: unmount to save resources, while retaining state in some context somewhere
  return (
    <BottomSheetContext.Provider value={value}>
      <Portal>
        <AnimatePresence>
          {sheets.length > 0 && (
            <div
              style={{
                overflow: 'hidden',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 999,
              }}
            >
              <BottomSheetBackdrop onClick={handleCloseSheet} />
              {sheets.map((curr, index) => (
                <BottomSheet
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${curr.meta.title}-${index}`}
                  isFirstSheet={index === 0}
                  hide={index !== sheets.length - 1}
                  title={curr.meta.title}
                  onClose={handleCloseSheet}
                  rightHeaderButton={curr.meta.rightHeaderButton}
                  style={curr.meta.style}
                >
                  {curr.node}
                </BottomSheet>
              ))}
            </div>
          )}
        </AnimatePresence>
      </Portal>
      {children}
    </BottomSheetContext.Provider>
  )
}

export default BottomSheetProvider
export { useBottomSheet }
