/**
 * @file Auth Progress Indicator Bar
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Purple } from '#/constants/colors'

const Container = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
`

const Indicator = styled.div<IndicatorProps>`
  height: 4px;
  width: 14px;
  border-radius: 2px;
  background-color: ${({ isActive }) => (isActive ? Purple : '#ffffff26')};
  transition: background-color 0.3s ease;
`

type IndicatorProps = {
  isActive?: boolean
}

type Props = {
  current: number
  total: number
}

const AuthProgressIndicator: React.FC<Props> = ({ current, total }) => (
  <Container>
    {Array.from({ length: total }).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Indicator key={`auth-progress-${index}`} isActive={current === index} />
    ))}
  </Container>
)

export default AuthProgressIndicator
