/**
 * @file Email Input Component for AuthModal
 * @author Alwyn Tan
 */

import React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import Input from '#/components/atoms/Input'
import Button from '#/components/molecules/Button'
import useUpdateUser from '#/hooks/query/user/useUpdateUser'
import AuthModalContent from './AuthModalContent'

type Props = {}

type FormValues = { email: string }

const EmailAuth: React.FC<Props> = () => {
  const { control, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onChange',
  })
  const updateUserMutation = useUpdateUser()

  const onSubmit: SubmitHandler<FormValues> = ({ email }) => {
    updateUserMutation.mutate({ email })
  }

  return (
    <AuthModalContent
      title="What’s Your Email?"
      subtitle="We’ll use your email to send you important notifications about your order"
      input={
        <Input
          size="regular"
          name="email"
          rules={{
            required: true,
            pattern: {
              value:
                /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/, // eslint-disable-line no-control-regex
              message: 'Enter a valid email',
            },
          }}
          control={control}
          inputProps={{
            autoFocus: true,
            placeholder: 'Enter Your Email',
            autoComplete: 'email',
          }}
          loading={updateUserMutation.isLoading}
        />
      }
      footer={
        <Button
          loading={updateUserMutation.isLoading}
          disabled={!formState.isValid}
          size="medium"
          onClick={handleSubmit(onSubmit)}
        >
          Done
        </Button>
      }
    />
  )
}

export default EmailAuth
