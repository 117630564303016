/**
 * @file All keys for react query
 * @author Alwyn Tan
 */

export const userKeys = {
  all: ['user'],
  me: () => [...userKeys.all, 'me'],
  details: () => [...userKeys.all, 'detail'],
  detail: (username: string) => [...userKeys.details(), username],
}

export const couponKeys = {
  all: ['coupon'],
  details: () => [...couponKeys.all, 'detail'],
  detail: (code: string) => [...couponKeys.details(), code],
}

export const dropKeys = {
  all: ['drop'],
  lists: () => [...dropKeys.all, 'list'],
  list: ({
    tags,
    sortBy,
  }: {
    tags?: string[]
    sortBy?: string
  } = {}) => [...dropKeys.lists(), { tags, sortBy }],
  likes: () => [...dropKeys.all, 'like'],
  like: (id: string) => [...dropKeys.likes(), id],
  details: () => [...dropKeys.all, 'detail'],
  detail: (id: string) => [...dropKeys.details(), id],
}

export const eventKeys = {
  all: ['event'],
  lists: () => [...eventKeys.all, 'list'],
  list: ({ city, timeFilter }: { city?: string; timeFilter?: string } = {}) => [
    ...eventKeys.lists(),
    { city, timeFilter },
  ],
  details: () => [...eventKeys.all, 'detail'],
  detail: (id: string) => [...eventKeys.details(), id],
}

export const experienceKeys = {
  all: ['experience'],
  lists: () => [...experienceKeys.all, 'list'],
  list: (dropID: string) => [...experienceKeys.lists(), dropID],
}

export const stripeKeys = {
  all: ['stripe'],
  paymentMethods: () => [...stripeKeys.all, 'payment-methods'],
}
