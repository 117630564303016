/**
 * @file Login Hook
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import toast from 'react-hot-toast'
import { userKeys } from '#/query-key-factory'
import { identify } from '#/services/analytics'
import { type IUser } from '#/types/models/user.model'

type LoginMutationParams = {
  phoneNumber: string
  code: string
}

type LoginResponseJSON = {
  user: IUser
  accessToken: string
}

const postLogin = async ({ phoneNumber, code }: LoginMutationParams) =>
  (
    await axios.post<LoginResponseJSON>(
      '/user/login',
      { phoneNumber, code },
      { withCredentials: true }
    )
  ).data

const useLogin = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ phoneNumber, code }: LoginMutationParams) => {
      const { accessToken, user } = await postLogin({ phoneNumber, code })

      // todo, shift this down to the callbacks...
      if (accessToken) delete user.fcmTokens

      return { ...user, accessToken }
    },
    onMutate: () => {
      const toastID = toast.loading('Loading')
      return { toastID }
    },
    onError: (result, variables, context) => {
      toast.error('Error Requesting Login', { id: context?.toastID })
    },
    onSuccess: (user, variables, context) => {
      toast.success('Logged In!', { id: context?.toastID })
      identify(user)
      queryClient.invalidateQueries(userKeys.me())
    },
  })
}

export default useLogin
