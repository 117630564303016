/**
 * @file Auth modal that is used
 * @author Alwyn Tan
 */

import { AnimatePresence, motion } from 'framer-motion'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import EmailAuth from '#/components/organisms/Auth/EmailAuth'
import NameAuth from '#/components/organisms/Auth/NameAuth'
import OTPAuth from '#/components/organisms/Auth/OTPAuth'
import PhoneAuth from '#/components/organisms/Auth/PhoneAuth'
import { DarkerGray } from '#/constants/colors'
import useCurrentUser from '#/hooks/query/user/useCurrentUser'
import { type IUser } from '#/types/models/user.model'
import AuthProgressIndicator from './AuthProgressIndicator'

const Container = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const Backdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000cc;
`

const ModalContainer = styled(motion.div)`
  background-color: ${DarkerGray};
  border-radius: 12px;
  padding: 24px;
  z-index: 1;
  width: 100%;
  max-width: 400px;
  margin: 0 16px 20px 16px;
`

const AuthModal = forwardRef((props, ref) => {
  const { data: currentUser } = useCurrentUser()
  const [isActive, setIsActive] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otp, setOTP] = useState('')
  const [progress, setProgress] = useState(0)
  const authCBRef = useRef<(user?: IUser) => void>(() => {})

  const resetModal = useCallback(() => {
    authCBRef.current = () => {}
    setIsActive(false)
    setPhoneNumber('')
    setOTP('')
    setProgress(0)
  }, [])

  useImperativeHandle(ref, () => ({
    present: (cb: (user?: IUser) => void) => {
      setIsActive(true)
      authCBRef.current = cb
    },
    dismiss: () => {
      resetModal()
    },
  }))

  useEffect(() => {
    // decide when to trigger auth callback and turn off isActive
    if (isActive && currentUser && currentUser.name && currentUser.email) {
      if (authCBRef.current) authCBRef.current(currentUser)
      resetModal()
    }
  }, [currentUser, isActive, resetModal])

  useEffect(() => {
    if (!currentUser && !phoneNumber) setProgress(0)
    else if (!currentUser && phoneNumber && !otp) setProgress(1)
    else if (currentUser && !currentUser.name) setProgress(2)
    else if (currentUser && !currentUser.email) setProgress(3)
    else setProgress(-1)
  }, [currentUser, otp, phoneNumber])

  const handlePhoneLoginComplete = (value: string) => {
    setPhoneNumber(value)
  }

  const handleOTPBackClick = () => {
    setPhoneNumber('')
  }

  const handleOTPComplete = (value: string) => {
    setOTP(value)
  }

  const renderModals = () => {
    if (progress === 0)
      return <PhoneAuth onComplete={handlePhoneLoginComplete} />

    if (progress === 1)
      return (
        <OTPAuth
          phoneNumber={phoneNumber}
          onBack={handleOTPBackClick}
          onComplete={handleOTPComplete}
        />
      )

    if (progress === 2) return <NameAuth />

    if (progress === 3) return <EmailAuth />

    return null
  }

  return (
    <AnimatePresence>
      {isActive && (
        <Container>
          <Backdrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.15 }}
            onClick={resetModal}
          />
          <ModalContainer
            initial={{ y: '100vh' }}
            animate={{ y: 0 }}
            exit={{ y: '100vh' }}
            transition={{ type: 'spring', duration: 0.4, bounce: 0.1 }}
          >
            <AuthProgressIndicator current={progress} total={4} />
            {renderModals()}
          </ModalContainer>
        </Container>
      )}
    </AnimatePresence>
  )
})

export default AuthModal
