/**
 * @file Stripe Context
 * @author Alwyn Tan
 */

import { loadStripe, type Stripe } from '@stripe/stripe-js'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

type TStripeContext = {
  stripe: Stripe | null
}

type TStripeProviderProps = {
  children: React.ReactNode
}

const StripeContext = createContext<TStripeContext>({
  stripe: null,
})

const useStripe = () => {
  const context = useContext(StripeContext)

  if (context === undefined)
    throw new Error('useStripe was called outside of its Provider')

  return context.stripe
}

const StripeProvider = ({ children }: TStripeProviderProps) => {
  const [stripe, setStripe] = useState<Stripe | null>(null)

  const value = useMemo(() => ({ stripe }), [stripe])

  // note: unlikely we need to invoke stripe immediately
  useEffect(() => {
    loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY).then(res => {
      setStripe(res)
    })
  }, [])

  return (
    <StripeContext.Provider value={value}>{children}</StripeContext.Provider>
  )
}

export default StripeProvider
export { useStripe }
