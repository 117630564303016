/**
 * @file Root Wrapper (for global stuff that doesnt rely on routes & won't rerender) Good article to read on: https://markoskon.com/wrap-root-element-vs-wrap-page-element/
 * @author Alwyn Tan
 */

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import AuthProvider from '#/contexts/auth-context'
import StripeProvider from '#/contexts/stripe-context'
import theme from '#/styles/theme'
import '#/styles/fonts.css'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1yr',
    yy: '%dy',
  },
})

// todo: this implementation has to change when switching cities
dayjs.tz.setDefault('America/New_York')

const queryClient = new QueryClient()

type Props = {
  children: React.ReactNode
}

const RootWrapper = ({ children }: Props) => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <StripeProvider>{children} </StripeProvider>
      </AuthProvider>
    </QueryClientProvider>
  </ThemeProvider>
)

export default RootWrapper
