/**
 * @file Wrapper for Auth Modal Content
 * @author Alwyn Tan
 */

import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import Text from '#/components/atoms/Text'

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

type Props = {
  title: string
  subtitle?: string | React.ReactNode
  input: React.ReactNode
  footer: React.ReactNode
}

const AuthModalContent: React.FC<Props> = ({
  title,
  subtitle,
  input,
  footer,
}) => (
  <Container
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ ease: 'easeInOut', duration: 0.15 }}
  >
    <TitleContainer>
      <Text type="h4">{title}</Text>
      {!!subtitle && (
        <Text type="p2" style={{ color: '#ffffff80' }}>
          {subtitle}
        </Text>
      )}
    </TitleContainer>
    {input}
    {footer}
  </Container>
)

export default AuthModalContent
