/**
 * @file Analytics
 * @author Alwyn Tan
 */

import {
  ALLOWED_ACTIONS,
  OBJECT,
  type TAction,
  type TObjectName,
} from '#/constants/analytics'
import { type IDrop } from '#/types/models/drop.model'
import { type IUser } from '#/types/models/user.model'

const makeEvent = (objectName: TObjectName, action: TAction) => {
  if (!ALLOWED_ACTIONS[objectName]?.[action])
    throw new Error(
      `Analytics action ${action} not allowed on object ${objectName}`
    )
  return `${objectName} ${action}`.trim()
}

const makeProperties = (objectName: TObjectName, data: any) => {
  switch (objectName) {
    case OBJECT.DROP: {
      return {
        'Drop ID': data?.drop?.id,
        Title: data?.drop?.title,
        Description: data?.drop?.description,
        Address: data?.drop?.address,
        Location: data?.drop?.location,
        Categories: data?.drop?.categories,
        Link: data?.drop?.link,
        Response: data?.drop?.response,
        'Active Date': data?.drop?.activeDate,
        'Created At': data?.drop?.createdAt,
        'Updated At': data?.drop?.updatedAt,
        Bookable: data?.drop?.hasExperience,
        'Has Custom Reqeust': data?.drop?.hasCustomRequest,
        'External Booking Link': data?.drop?.extBookingLink,
        Tags: data?.drop?.tags,
        'Pressed Tag': data?.pressedTag,
      }
    }
    case OBJECT.PARTNER_MODAL: {
      return {
        Partner: data?.drop?.partner,
      }
    }
    default:
      return {}
  }
}

// backend identifies with traits, frontend only the id
export const identify = (user: IUser) => {
  window.analytics.identify(user.id, { id: user.id })
}

export const reset = () => {
  analytics.reset()
}

export const trackPartnerModal = (action: TAction, partner: string) => {
  const objectName = OBJECT.PARTNER_MODAL
  const event = makeEvent(objectName, action)
  const properties = makeProperties(objectName, { partner })

  window.analytics.track(event, properties)
}

export const trackDrop = (action: TAction, drop: IDrop) => {
  const objectName = OBJECT.DROP
  const event = makeEvent(objectName, action)
  const properties = makeProperties(objectName, { drop })

  window.analytics.track(event, properties)
}
