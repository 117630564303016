/**
 * @file Name Input Component for AuthModal
 * @author Alwyn Tan
 */

import React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import Input from '#/components/atoms/Input'
import Button from '#/components/molecules/Button'
import useUpdateUser from '#/hooks/query/user/useUpdateUser'
import AuthModalContent from './AuthModalContent'

type Props = {}

type FormValues = { name: string }

const NameAuth: React.FC<Props> = () => {
  const { control, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onChange',
  })
  const updateUserMutation = useUpdateUser()

  const onSubmit: SubmitHandler<FormValues> = ({ name }) => {
    updateUserMutation.mutate({ name })
  }

  return (
    <AuthModalContent
      title="What's Your Name?"
      input={
        <Input
          size="regular"
          name="name"
          rules={{ required: true }}
          control={control}
          inputProps={{
            autoFocus: true,
            placeholder: 'Enter Your Name',
            autoComplete: 'name',
          }}
          loading={updateUserMutation.isLoading}
        />
      }
      footer={
        <Button
          loading={updateUserMutation.isLoading}
          disabled={!formState.isValid}
          size="medium"
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </Button>
      }
    />
  )
}

export default NameAuth
